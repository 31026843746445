<template>
    <div>
        <block v-if="loading"></block>
        <v-card flat>
            <v-card-text>
                <template v-if="type=== 'medicalclinic'">
                    <user-profile-lab :patient="data" :patientinfo="data" :useData="useData"></user-profile-lab>
                </template>
                <template v-else>
                    <user-profile :patientinfo="patientinfo" :data="data" :useData="useData" />
                </template>

                <!-- Prescription Form -->
                <v-row style="margin: 16px 2px 0;">
                    <block v-if="loading"></block>

          <v-col cols="12" class="box" style="background-color: white;margin-top: 1rem;">
            <v-form v-model="valid" class="position-relative">

              <v-row class="ml-2 mr-2" style="margin-top: .3rem;">

                <v-col class="tabsNh mb-4" cols="12">

                  <v-col cols="12" md="12" v-if="selectedvalues" style="display: flex; justify-content: space-between">

                    <div style="width: 212px;" ></div>
                    <div>
                        <v-btn v-if="$store.getters['rolesUser/getCustomRole'](107)" small class="btn-add mr-1" @click.prevent="getItemsToBeBilled(); showBillPatient = true">
                            <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                        </v-btn>
                    </div>
                    <div>
                      <v-btn small class="mr-1"
                      v-if="$store.getters['rolesUser/getCustomRole'](162)"
                        @click="assigning( prescription, patientinfo, useData, usenextappointment )">
                        <v-icon>
                          mdi-printer
                        </v-icon>
                      </v-btn>
                      <v-btn small class="btnblue mr-1" @click="showPrescriptionHistory = !showPrescriptionHistory" v-if="$store.getters['rolesUser/getCustomRole'](140)">
                        <v-icon>
                          mdi-magnify
                        </v-icon>
                      </v-btn>
  
                      <v-btn small class="btnclean" @click.prevent="resetData()">
                        <v-icon>
                          mdi-broom
                        </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="$store.getters['rolesUser/getCustomRole'](141)"
                        small class="btnsave" @click.prevent.stop="addPrescription()">
                        <v-icon>
                          mdi-content-save
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>

                                    <v-col cols="12" v-if="prescription.id_pvp" style=" display: flex; justify-content: space-between;">
                                        <div>
                                            <v-btn v-if="$store.getters['rolesUser/getCustomRole'](142)" small color="error"
                                                @click.prevent.stop='showAlert("question", "Confirmar", "Esta seguro de borrar el elemento esta acción no se puede dehacer", "deleteprescription"); confirmtoDelete = prescription'>
                                                <v-icon>
                                                mdi-delete
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                        <div class="d-flex">
                                            <v-btn small class="mr-1"
                                                v-if="$store.getters['rolesUser/getCustomRole'](162)" @click="assigning( prescription, patientinfo, useData, usenextappointment )">
                                                <v-icon>
                                                mdi-printer
                                                </v-icon>
                                            </v-btn>
                                            <v-btn 
                                                v-if="$store.getters['rolesUser/getCustomRole'](140)" small class="btnblue mr-1" @click="showPrescriptionHistory = true">
                                                <v-icon>
                                                mdi-magnify
                                                </v-icon>
                                            </v-btn>
                                            <v-btn small class="btnclean" @click.prevent="resetData()">
                                                <v-icon>
                                                mdi-broom
                                                </v-icon>
                                            </v-btn>
                                            <div>
                                                <v-btn 
                                                    v-if="$store.getters['rolesUser/getCustomRole'](141)" small class="btnsave" @click.prevent="updatePrescription()">
                                                    <v-icon>
                                                        mdi-content-save
                                                    </v-icon>
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-col>
                            </v-row>	

                            <!-- Date & Comments -->
                            <v-row class="mx-1">
                                <v-col cols="12" md="2" sm="12">
                                    <date-picker label="Fecha" @dateSelected="(param) => {
                                        prescription.prescriptiondate = param
                                        }
                                        " :value="prescription.prescriptiondate">
                                    </date-picker>
                                </v-col>
                                <v-col cols="6" md="2">
                                    <v-text-field label="Peso" dense outlined v-model="weight" @change="changeWeight"></v-text-field>
                                </v-col>
                                <v-col cols="6" md="2">
                                    <v-select outlined dense attach @change="switchWeightUnit" v-model="weightunit" :items="['Kg', 'Lb']" label="Unidad de peso"></v-select>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <div style="display: flex;justify-content: flex-end;">
                                        <v-checkbox v-model="usenextappointment" label="Próxima cita"
                                        @change="NextAppointmentFunction($event)" value="Appointment"></v-checkbox>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <div style="display: flex;justify-content: flex-end;">
                                        <date-picker @dateSelected="(param) =>
                                            (prescription.nextappointment = param)
                                            " :value="prescription.nextappointment" :disbaled="usenextappointment.length < 1">
                                        </date-picker>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="2"  style="display: flex;justify-content: flex-end;">
                                    <v-btn @click="showComments = !showComments" this.prescription.ID_PVP=data.ID_PVP;
                                        :class="prescription.comment ? 'btnclean' : 'btnblack'">
                                        Comentarios
                                    </v-btn>
                                </v-col>
                            </v-row>

                            <!-- Quill Editor -->
                            <v-row class="mb-5 px-3">
                                <v-col cols="12">
                                    <span class="ql-editor300">
                                        <quill-editor v-model="prescription.prescription" :options="quillOptions"
                                        @change="onEditorChange($event)"></quill-editor>
                                    </span>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>

        <v-dialog v-model="showPrescriptionHistory" :width="'90%'" scrollable>
          <v-card class="pa-4">
            <v-card-text>
              <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
                <v-btn small class="btndelete" @click.prevent="showPrescriptionHistory = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <GeneralFilter 
                class="background border-box" 
                entity="cliente"
                :filterEndpoint="$API.veterinaryprescription.getFilterVeterinaryPrescription" 
                :search="search"
                @emptyFilter="getPrescriptions" 
                @filtered="filterHandler"
              >
                <DxDataGrid 
                  class="isScrolledByGeneralFilter" 
                  @exporting="onExporting" 
                  :show-borders="true" 
                  :data-source="prescriptions" 
                  key-expr="ID_PVP" 
                  :column-auto-width="true"
                  style="width: fit-content;"
                >
                  <DxExport :enabled="true" />
                  <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                  <DxColumn data-field="PrescriptionDate" caption="Fecha" :min-width="100"></DxColumn>
                  <DxColumn data-field="NextAppointment" caption="Próxima cita" :min-width="100"></DxColumn>
                  <DxColumn data-field="Weight" caption="Peso" :min-width="100"></DxColumn>
                  <DxColumn data-field="fullName" caption="Atendido por" :min-width="200"></DxColumn>
                  <DxColumn data-field="PrescriptionTable" caption="Prescripción" :min-width="200" cssClass="left-align"></DxColumn>
                  <DxColumn
                    v-if="$store.getters['rolesUser/getCustomRole'](142)"
                    :width="80" cell-template="show-delete" caption=""></DxColumn>
                  <template #show-delete="{ data }">
                    <div>
                      <a href="" class="mx-2"
                        @click.prevent.stop='showAlert("question", "Confirmar", "Esta seguro de borrar el elemento esta accion no se puede dehacer", "deleteprescription"); confirmtoDelete = data.data; showPrescriptionHistory = false'>
                        <v-icon color="error">mdi-delete</v-icon>
                      </a>
                    </div>
                  </template>
                  <template #show-template="{ data }">
                    <div>
                      <a href="#" @click.prevent.stop="selectedPrescription(data.data); showPrescriptionHistory = false">
                        <v-icon color="primary">mdi-eye</v-icon>
                      </a>
                    </div>
                  </template>
                </DxDataGrid>
              </GeneralFilter>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Dialog bill patient -->
        <v-dialog v-model="showBillPatient" width="90%">
          <v-card class="py-3 px-3">
              <v-card-title class="text-h5">
              Facturar paciente 
              </v-card-title>

              <v-card-actions>
              <v-row>
                  <v-col cols="12" class="tabsNh" style="padding: 14px">
                  <v-col style="display: flex; justify-content: space-between; min-height: 50px">
                      <div>
                      <!-- <v-btn v-if="!showServicesToAdd" small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                          <v-icon>mdi-menu-down</v-icon>
                          Ver items
                      </v-btn>
                      <v-btn v-else small class="btn-add" style="width: 100px;" @click.prevent="showServicesToAdd = !showServicesToAdd">
                          <v-icon>mdi-menu-up</v-icon>
                          Ocultar
                      </v-btn> -->
                      </div>
                      <div>
                      <v-btn small class="btn-add" @click.prevent="saveItemsToBeBilled()">
                          <v-icon>mdi-content-save</v-icon>
                          Guardar
                      </v-btn>
                      </div>

                      <div>
                      <v-btn small class="btnsave" @click.prevent="billedPatientsOfCustomer()">
                          <v-icon>mdi-cash-register</v-icon>
                          Facturar cliente
                      </v-btn>
                      </div>
                  </v-col>
                  </v-col>
              </v-row>
              </v-card-actions>
              <div style="height: 500px; overflow-y: auto; overflow-x: hidden; margin-top: 20px; margin-bottom: 20px; padding: 0 20px;">
              <!-- Services default -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver servicios por defecto
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide1 = !togglehide1' v-show='!togglehide1' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide1 = !togglehide1' v-show='togglehide1' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide1">
                  <DxDataGrid class="isScrolledByGeneralFilter dataGridService" :data-source="servicesDefault" 
                  :show-borders="true" :column-auto-width="true" style="width: fit-content;">
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="CodeService" header-cell-template="headerCodeService" />
                      <template #headerCodeService="{ }">
                      <div>Código de<br />servicio</div>
                      </template>
                      <DxColumn data-field="NameService" header-cell-template="headerNameService" />
                      <template #headerNameService="{ }">
                      <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" header-cell-template="headerName" />
                      <template #headerName="{ }">
                      <div>Nombre a<br />facturar</div>
                      </template>
                      <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice" />
                      <template #headerTypeservice="{ }">
                      <div>Tipo de<br />servicio</div>
                      </template-->
                      <DxColumn :width="65" data-field="Price" caption="Precio" />
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""/>
                      <template #show-template="{ data }">
                      <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                          <v-icon>mdi-plus</v-icon>
                      </a>
                      </template>
                      <template #quantity-template="{ data }">
                      <p>{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService).quantity }}</p>
                      </template>
                  </DxDataGrid>
                  </v-col>
              </v-row>

              <!-- Services to select -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver todos los servicios
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide2 = !togglehide2' v-show='!togglehide2' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide2 = !togglehide2' v-show='togglehide2' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide2">
                  <GeneralFilter 
                      :filterEndpoint="$API.services.getAllServicesFiltered" 
                      :search="searchToBill"
                      @emptyFilter="getServices" 
                      @filtered="servicesFilterHandler"
                  >
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="servicesToBill"
                      key-expr="CodeService" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="CodeService" caption="ID"></DxColumn>
                      <DxColumn data-field="NameService" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <!--DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice">
                      </DxColumn-->
                      <!--template #headerTypeservice="{ }">
                          <div>Tipo de<br />servicio</div>
                      </template-->
                      <DxColumn :width="65" data-field="Price" caption="Precio"></DxColumn>
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                          <v-icon>mdi-plus</v-icon>
                          </a>
                      </template>
                      <template #quantity-template="{ data }">
                          <p style="margin: 0;">{{ servicesToAdd.find(item => item.CodeService == data.data.CodeService)?.quantity || '' }}</p>
                      </template>
                      </DxDataGrid>
                  </GeneralFilter>
                  </v-col>
              </v-row>

              <!-- Products to select -->
              <v-row style="margin-top: 2rem">
                  <v-row class="d-flex justify-center mb-2">
                  <v-col cols="10" md="10" style="text-align: center; padding: 0px 0px 0px 0px !important;">
                      <v-alert class="btndisable" style="padding: 4px !important; margin-bottom: 0px !important">
                      Ver todos los productos
                      </v-alert>
                  </v-col>
                  <v-col cols="1" md="1" style="text-align: right; padding: 0px 0px 0px 0px !important">
                      <v-alert class="btndisable2" style="padding: 4px !important; margin-bottom: 0px !important">
                      <button @click='togglehide3 = !togglehide3' v-show='!togglehide3' style="padding: 0px 4px 0px 4px !important"> Minimizar </button>
                      <button @click='togglehide3 = !togglehide3' v-show='togglehide3' style="padding: 0px 4px 0px 4px !important"> Ampliar </button>
                      </v-alert>
                  </v-col>
                  </v-row>
                  <v-col cols="12" v-if="!togglehide3">
                  <GeneralFilter 
                      :filterEndpoint="$API.products.getProductsToBillForInvoiceFiltered" 
                      :search="searchToBill"
                      @emptyFilter="getProducts" 
                      @filtered="productsFilterHandler"
                  >
                      <v-row style="padding: 1rem;">
                        <v-col cols="12" md="3" sm="4">
                            <v-autocomplete
                            v-model="selectedWarehouse"
                            item-text="Warehouse"
                            item-value="ID_Warehouse"
                            :items="warehouses"
                            @change="handleSelectWarehouse"
                            label="Almacenes"
                            ref="werehouseRef"
                            dense 
                            outlined 
                            attach
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="9" sm="8">
                            <p style="color: #e3191e !important; font-size: 12px; margin: 12px 0" v-if="warehouses.length == 0">No existen almacenes de productos para pacientes.</p>
                        </v-col>
                      </v-row>
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="productsToBill"
                      key-expr="ID_Item" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="20" />
                      <DxColumn :width="150" data-field="ID_Item" caption="ID"></DxColumn>
                      <DxColumn data-field="ItemName" header-cell-template="headerName"></DxColumn>
                      <template #headerName="{ }">
                          <div>Nombre del<br />producto</div>
                      </template>
                      <DxColumn data-field="ItemComercialName" header-cell-template="headerNameComercial"></DxColumn>
                      <template #headerNameComercial="{ }">
                          <div>Nombre<br />comercial</div>
                      </template>
                      <DxColumn :width="65" data-field="ItemPrice" caption="Precio"></DxColumn>
                      <DxColumn :width="45" cell-template="quantity-template" caption=""/>
                      <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="addProduct(data.data)">
                          <v-icon>mdi-plus</v-icon>
                          </a>
                      </template>
                      <template #quantity-template="{ data }">
                        <p style="margin: 0;">{{ productsToAdd.find(item => item.ID_Item == data.data.ID_Item)?.quantity || '' }}</p>
                      </template>
                      </DxDataGrid>
                  </GeneralFilter>
                  </v-col>
              </v-row>

              <!-- ServiceToAdd -->
              <v-row style="margin-top: 2rem">
                  <v-col cols="12">
                  <h4 style="padding: 16px;">Servicios seleccionados</h4>
                  <div style="overflow-x: scroll; padding: 0 12px;">
                      <DxDataGrid 
                      class="isScrolledByGeneralFilter dataGridService" 
                      :data-source="servicesToAdd"
                      key-expr="CodeService" 
                      :allow-column-reordering="true" 
                      :row-alternation-enabled="true"
                      :show-borders="true"    
                      :column-auto-width="true"
                      style="width: fit-content;"
                      >
                      <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                      <DxScrolling row-rendering-mode="virtual" />
                      <DxPaging :page-size="5" />
                      <DxColumn data-field="CodeService" :allow-editing="false" caption="ID"></DxColumn>
                      <DxColumn data-field="NameService" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                      <template #headerNameService="{ }">
                          <div>Nombre de<br />servicio</div>
                      </template>
                      <DxColumn data-field="Name" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                      <template #headerNameLab="{ }">
                          <div>Nombre a<br />facturar</div>
                      </template>
                      <DxColumn data-field="TypeService" :allow-editing="false" header-cell-template="headerTypeservice">
                      </DxColumn>
                      <template #headerTypeservice="{ }">
                          <div>Tipo de<br />servicio</div>
                      </template>
                      <DxColumn data-field="quantity" caption="Cantidad">
                          <DxFormat typed="fixedPoint" :precision="0" />
                          <DxRequiredRule message="La cantidad del servicio a facturar es requerida" />
                          <DxPatternRule :pattern="itemQtyPattern"
                          message="La cantidad del servicio facturado solo puede contener numeros enteros mayores a 0" />
                      </DxColumn>
                      <DxColumn data-field="unitPrice" :allow-editing="false" caption="Precio"></DxColumn>
                      <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                      <DxColumn cell-template="show-template" caption=""></DxColumn>
                      <template #show-template="{ data }">
                          <a href="#" class="mx-1" @click.prevent.stop="deleteService(data.data)">
                          <v-icon>mdi-delete</v-icon>
                          </a>
                      </template>
                      <template #total-template="{ data }">
                          <div>
                          {{ totalXQuantity( data.data ) }}
                          </div>
                      </template>
                      </DxDataGrid>
                  </div>
                  </v-col>
              </v-row>

              <!-- Product to add -->
              <v-row style="margin-top: 2rem">
                <v-col cols="12">
                <h4 style="padding: 16px;">Productos seleccionados</h4>
                <div style="overflow-x: scroll; padding: 0 12px;">
                    <DxDataGrid 
                    class="isScrolledByGeneralFilter dataGridService" 
                    :data-source="productsToAdd"
                    key-expr="ID_Item" 
                    :allow-column-reordering="true" 
                    :row-alternation-enabled="true"
                    :show-borders="true"    
                    :column-auto-width="true"
                    style="width: fit-content;"
                    >
                    <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                    <DxScrolling row-rendering-mode="virtual" />
                    <DxPaging :page-size="5" />
                    <DxColumn data-field="ID_Item" :allow-editing="false" caption="ID"></DxColumn>
                    <DxColumn data-field="ItemName" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                    <template #headerNameService="{ }">
                        <div>Nombre del<br />producto</div>
                    </template>
                    <DxColumn data-field="ItemComercialName" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                    <template #headerNameLab="{ }">
                        <div>Nombre a<br />facturar</div>
                    </template>
                    <DxColumn data-field="ItemType" :allow-editing="false" header-cell-template="headerTypeservice">
                    </DxColumn>
                    <template #headerTypeservice="{ }">
                        <div>Tipo de<br />producto</div>
                    </template>
                    <DxColumn data-field="quantity" caption="Cantidad">
                        <DxFormat typed="fixedPoint" :precision="0" />
                        <DxRequiredRule message="La cantidad del producto a facturar es requerida" />
                        <DxPatternRule :pattern="itemQtyPattern"
                        message="La cantidad del producto facturado solo puede contener numeros enteros mayores a 0" />
                    </DxColumn>
                    <DxColumn data-field="unitPrice" :allow-editing="false" caption="Precio"></DxColumn>
                    <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                    <DxColumn cell-template="show-template" caption=""></DxColumn>
                    <template #show-template="{ data }">
                        <a href="#" class="mx-1" @click.prevent.stop="deleteProduct(data.data)">
                        <v-icon>mdi-delete</v-icon>
                        </a>
                    </template>
                    <template #total-template="{ data }">
                        <div>
                        {{ totalXQuantity( {...data.data, Price: data.data.ItemPrice} ) }}
                        </div>
                    </template>
                    </DxDataGrid>
                </div>
                </v-col>
              </v-row>

              </div>
          </v-card>
         </v-dialog>
        <!-- Dialog bill patient -->

        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>

                <!-- Prescription Comments Modal -->
                <v-dialog v-model="showComments" max-width="60%">
                    <v-card class="pa-4">
                        <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
                        <v-btn small class="btndelete" @click.prevent="showComments = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        </div>
                        <v-col cols="12" md="12" style="">
                        <quill-editor v-model="prescription.comment" :options="quillOptions"></quill-editor>
                        </v-col>
                    </v-card>
                </v-dialog>

                <!-- Prescription Print Preview-->
                <PrescriptionShort :data="print" />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import Vue from "vue"
import Block from "@/components/Block"
import { DxDataGrid, DxColumn, DxPaging, DxScrolling, DxEditing, DxFormat, DxPatternRule, DxRequiredRule, DxExport } from "devextreme-vue/data-grid"
import DatePicker from "../../../components/DatePicker"
import Json from "../../../assets/Json/printTemplate.json"
import Alerts from "@/components/Alerts"
import PrescriptionShort from "../../../components/ReportsAndPrintings/PrescriptionShort.vue"
import { mapState } from "vuex"
import { printToExcel } from "@/helpers/printToexcel"
import GeneralFilter from "@/components/GeneralFilter"
import UserProfile from "@/components/Displays/UserProfileDisplay.vue";
import UserProfileLab from "@/components/Displays/UserProfileDisplayLab.vue";
import { numberWithCommas } from "@/helpers/money";

export default {
  name: "VeterinaryPrescription",
  components: {
    DxDataGrid, DxColumn, DxEditing, DxPaging, DxFormat, DxPatternRule, DxScrolling, DxRequiredRule, DatePicker, Block, Alerts, //Prescription,
    DxExport, UserProfile, UserProfileLab,
    GeneralFilter, PrescriptionShort
  },
  props: ["data", "win",'type'],
  data: () => ({
    currency: JSON.parse(localStorage.getItem('branch')).Currency,
    itemQtyPattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/i,
    showBillPatient: false,
    useData: {},
    showServicesToAdd: false,
    showProductsToAdd: false,
    servicesToBill: [],
    productsToBill: [],
    servicesToAdd: [],
    productsToAdd: [],
    filterProducts: {
      filter: null
    },
    searchToBill: {
        filter: null
    },
    togglehide1: true,
    togglehide2: true,
    togglehide3: true,
    warehouses: [],
    selectedWarehouse: null,
    customer: {},
    print: {
      show: false,
      data: []
    },
    alert: {
      type: "success",
      show: false,
      header: "",
      body: "",
    },
    confirmtoDelete: null,
    valid: false,
    search: {
    },
    url: null,
    patientinfo: [],
    weightunit: '',
    weight: '',
    prescriptions: {},
    prescription: {},
    loading: false,
    id_user_login: null,
    usenextappointment: [],
    dialogprint: false,
    searchbusiness: {},
    infobusiness: {},
    na: null,
    header: {},
    footer: {},
    template: {},
    prescriptionJSON: {},
    selectedvalues: true,
    showPrescriptionHistory: false,
    showComments: false,
    quillOptions: {
      modules: {
        toolbar: {
          container:
            [
            //   [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            //   ['bold', 'italic', 'underline', 'strike'],
            ['bold', 'underline', 'strike'],
            //   ['blockquote', 'code-block'],
              ['blockquote'],
              //[{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'list': 'bullet' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'align': [] }],
            //   ['clean'],
              [{ 'color': [] }, { 'background': [] }],
            //   [{ 'font': [] }],
            //   [{ 'size': ['small', false, 'large', 'huge'] }],
            //   [{ 'script': 'sub' }, { 'script': 'super' }],
            //   [{ 'formula': '' }]
            ],
          handlers: {}
        }
      },
      theme: 'snow'
    },
    //useData: {},
    policlinicaclientdata: JSON.parse(localStorage.getItem("user")).policlinicaclientdata
  }),
  computed: {
    ...mapState(
      "rolesUser",
      {
        rolesStatus: (state) => state.roles,
      })
  },
  async mounted() {
    this.prescriptionJSON = Json[0]['Prescription']
    this.$store.dispatch('rolesUser/getAllRoles')
    this.useData = JSON.parse(localStorage.getItem("user"))
    this.getHeaderFooter()
    // this.getBodyTemplate();
    this.searchbusiness.branch = JSON.parse(localStorage.getItem("user")).branch || ""
    this.searchbusiness.business = JSON.parse(localStorage.getItem("user")).businessid || ""
    this.getInfoBranch()
    if (this.data) {
      this.prescription.Weight = this.data.Weight;
      Object.keys(this.data).map((el) => {
        Vue.set(this.patientinfo, el.toLowerCase(), this.data[el])
      })
      this.url =
        this.$store.getters.getBackendUrl +
        "/public" +
        this.data.ProfilePicPath
      this.getPrescriptions()
      console.log(this.prescriptions);
      this.branchConfig = JSON.parse(localStorage.getItem("branch"));
      if(this.branchConfig?.weightunit) this.weightunit = this.branchConfig.weightunit;
      if(this.data?.weightunit) this.weightunit = this.data.weightunit;
    }

    const localparams = JSON.parse(localStorage.getItem("localparams")) || null;
    if (localparams && localparams.weightunit) this.weightunit = localparams.weightunit;

    if(this.prescription && this.prescription.Weight.includes('Kg')) this.weightunit = 'Kg';
    if(this.prescription && this.prescription.Weight.includes('Lb')) this.weightunit = 'Lb';

    if(this.prescription.Weight && this.prescription.Weight.includes(this.weightunit)) {
        this.weight = this.prescription.Weight.slice(0, -2).replace(' ', '');
    } else {
        this.weight = this.prescription.Weight.replace(' ', '');
    }

    Vue.set(this.prescription, "id_patient", this.patientinfo.id_patient)
    this.search.id_patient = this.patientinfo.id_patient
    this.id_user_login = JSON.parse(localStorage.getItem("user")).id

    this.customer.ID_Customer = this.data.ID_Customer;
    console.log('customer', this.customer);
    //this.useData = JSON.parse(localStorage.getItem("user"));
    this.warehouses = await this.$API.warehouse.getWarehousesFiltered({ isPatient: true });
    this.filterProducts.business = this.useData.businessid;
    this.filterProducts.branch = this.useData.branch;
    this.selectedWarehouse = this.warehouses.length == 0 ? 0 : this.warehouses[0].ID_Warehouse;
    this.filterProducts.warehouse = this.selectedWarehouse;
    await this.getServices();
    await this.getProducts();
    await this.getDefaultServices();
  },
  watch: {
    servicesToAdd: {
        deep: true,
        immediate: true,
        handler() {
            if(this.servicesToAdd.length !== 0) {
                this.showServicesToAdd = true;
            }
            else {
                this.showServicesToAdd = false;
            }
        }
    },
    productsToAdd: {
        deep: true,
        immediate: true,
        handler() {
            if(this.productsToAdd.length !== 0) {
                this.showProductsToAdd = true;
            }
            else {
                this.showProductsToAdd = false;
            }
        }
    },
  },
  methods: {
    totalXQuantity( { Price, quantity } ) {
        return `${this.currency}${ numberWithCommas( parseFloat( Price * quantity ).toFixed( 2 ) ) }`
    },
    async handleSelectWarehouse() {
        this.loading = true;
        this.filterProducts.warehouse = this.selectedWarehouse;
        await this.getProducts();
        this.$refs.werehouseRef.blur();
        this.loading = false;
    },
    addService(data) {
        const isRepetitive = this.servicesToAdd.find((service) => service.ID_Service === data.ID_Service);

        if (!isRepetitive) {
            this.servicesToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.Price), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.Price).toFixed(4))}` });
        } else {
            const service = this.servicesToAdd.find(item => item.ID_Service === data.ID_Service)
            if(service) {
                service.quantity++;
            }
            console.log("Service already exists in servicesToAdd");
        }
    },
    addProduct(data) {
        const isRepetitive = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
        if (!isRepetitive) {
            this.productsToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.ItemPrice), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.ItemPrice).toFixed(4))}` });
        } else {
            const product = this.productsToAdd.find((item) => item.ID_Item === data.ID_Item);
            if(product) {
                product.quantity++;
            }
            console.log("Product already exists in productsToAdd.");
        }
    },
    deleteService(data) {
        const serviceIndex = this.servicesToAdd.findIndex((service) => service.CodeService === data.CodeService);

        if (serviceIndex !== -1) {
            this.servicesToAdd.splice(serviceIndex, 1);
        }
    },
    deleteProduct(data) {
        const index = this.productsToAdd.findIndex((item) => item.ID_Item === data.ID_Item);

        if (index !== -1) {
            this.productsToAdd.splice(index, 1);
        }
    },
    getServices() {
        this.$API.services.getAllServices(this.filterServices).then((response) => {
            this.servicesToBill = response;
        });
    },
    getProducts() {
        this.$API.products.getProductsToBillForInvoice(this.filterProducts).then((response) => {
            this.productsToBill = response;
        });
    },
    getDefaultServices() {
        this.$API.services.getDefaultServices().then((response) => {
            this.servicesDefault = response;
        });
    },
    servicesFilterHandler(response) {
        this.servicesToBill = response;
    },
    productsFilterHandler(response) {
        this.productsToBill = response;
    },
    getItemsToBeBilled() {
        this.$API.veterinarypatients.getServicesToBeBilled({ 'customer': this.data.ID_Customer })
        .then((response) => {
            this.servicesToAdd = []
            for(let i = 0; i < response.length; i++){
            this.servicesToAdd.push({ ...response[i], unitPrice: `${this.currency}${numberWithCommas(parseFloat(response[i].Price).toFixed(4))}` });
            }
        });
        this.$API.veterinarypatients.getProductsToBeBilled({ 'customer': this.data.ID_Customer }).then((res) => {
            if(res.success) {
            this.productsToAdd = [];
            for (const item of res.data) {
                this.productsToAdd.push({ ...item, quantity: item.Quantity, unitPrice: `${this.currency}${numberWithCommas(parseFloat(item.ItemPrice).toFixed(4))}`});
            }
            }
        });
    },
    async saveItemsToBeBilled() {
        this.showBillPatient = false;
        this.loading = true;

        try {
            if(this.servicesToAdd.length) {
            await this.$API.veterinarypatients.saveServicesToBeBilled({ 'customer': this.customer.ID_Customer, 'services': this.servicesToAdd })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Ocurrio un error al guardar los servicios.');
            });
            }
            if(this.productsToAdd.length) {
            console.log(this.productsToAdd);
            
            await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
                throw new Error('Ocurrio un error al guardar los productos.');
            });
            }
            await this.getItemsToBeBilled();
            this.loading = false;
            this.showAlert("success", "Exito", "Los datos de facturación de este paciente han sido guradados.")
        } catch (error) {
            console.log(error);
            this.loading = false;
            this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
        }

    },
    async billedPatientsOfCustomer() {
        this.showBillPatient = false;
        this.loading = true;

        try{
            await this.$API.veterinarypatients.saveServicesToBeBilled({'customer': this.customer.ID_Customer, 'services': this.servicesToAdd});
            await this.$API.veterinarypatients.saveProductsToBeBilled({ 'customer': this.customer.ID_Customer, 'products': this.productsToAdd });
            await this.$API.veterinarypatients.billedPatientsOfCustomer({ 'customer': this.customer.ID_Customer});

            this.loading = false;

            this.showAlert("success", "Exito", `Se ha realizado la factura de los ${this.servicesToAdd.length && this.productsToAdd.length ? 'servicios y productos' : this.servicesToAdd.length ? 'servicios' : this.productsToAdd.length ? 'productos' : 'items' } seleccionados, podrá observarla en el módulo de POS en listo para facturar.`);
            await this.getItemsToBeBilled();        
        }
        catch(error) {
            console.log(error)
            this.loading = false
            this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
        }
    },

    assigning( prescription, patientinfo, useData, usenextappointment ) {
      this.print = {
        show: true,
        data: {
          prescription: prescription,
          patientinfo: patientinfo,
          useData: useData,
          customer: patientinfo.customer,
          nextappointment: usenextappointment
        }
      };
    },
    onExporting(e) {
      printToExcel(e, "Prescripciones")
    },
    changeWeight(e) {
      this.prescription.Weight = e.replace(' ', '');
      console.log('weigth', this.prescription.Weight);
    },
    switchWeightUnit(e) {
      this.weightunit = e;
      console.log(this.weightunit);
    },
    getHeaderFooter() { 

      let search = {
        ID_branch: JSON.parse(localStorage.getItem("user")).branch || "",
        ID_business: JSON.parse(localStorage.getItem("user")).businessid || ""
      }
      this.$API.HF.getHF(search).then((data) => {
        let header = [], footer = []
        header = data.filter((x) => x.State == '1' && x.Type == 'Header')
        this.header = header.length > 0 ? JSON.parse(header[0].Body).html : ''
        footer = data.filter((x) => x.State == '1' && x.Type == 'Footer')
        this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : ''
      })
    },
    getBodyTemplate() {
      let data = {
        obj: {
          Branch_logo: this.infobusiness.BranchLogoPath,
          BusinessName: this.infobusiness.BusinessName,
          BranchName: this.infobusiness.BranchName,
          BranchAddress: this.infobusiness.BranchAddress,
          BranchPhone: this.infobusiness.BranchPhone,
          BranchEmail: this.infobusiness.BranchEmail,
          id_customer: this.patientinfo.id_customer,
          id_patient: this.patientinfo.id_patient,
          namepatient: this.patientinfo.namepatient,
          species: this.patientinfo.species,
          breed: this.patientinfo.breed,
          UserNextAppointmentLength: this.usenextappointment.length,
          next_appointment_date: this.prescription.nextappointment,
          prescription: this.prescription.prescription
        },
        type: 'Prescription'
      }
      this.$API.TM.getResolveTemplate(data).then((x) => {
        console.log(x)
        this.template = x[0]
        this.template.Body = JSON.parse(this.template.Body).html
        this.dialogprint = true
      }, (err) => {
        console.log(err)
      })
      // this.$API.TM.getTM().then((data)=>{
      //   this.template = data.filter((x)=>x.Type == 'Prescription')[0];
      // var html = this.template.Body.replace("{{Name}}", "Test1")
      // html = html.replace("@username", "alex@gmail.com")
      // html = html.replace("@password", "f1_M1@j3[I2~")
      // html = html.replace("@website", "www.google.com")
      // this.template.Body = JSON.parse(html).html
      // Object.entries(this.prescriptionJSON).map(([k]) => {
      //   this.template.Body = this.template.Body.replace(`@${k}`,
      //   k=="Branch_logo" ? this.infobusiness.BranchLogoPath
      //    : k=="BusinessName" ? this.infobusiness.BusinessName
      //     : k=="BranchName" ? this.infobusiness.BranchName
      //       : k=="BranchAddress" ? this.infobusiness.BranchAddress
      //        : k=="BranchPhone" ? this.infobusiness.BranchPhone
      //         : k=="BranchEmail" ? this.infobusiness.BranchEmail
      //           : k=="id_customer" ? this.patientinfo.id_customer
      //            : k=="id_patient" ? this.patientinfo.id_patient
      //             : k=="namepatient" ? this.patientinfo.namepatient
      //              : k=="species" ? this.patientinfo.species
      //               : k=="breed" ? this.patientinfo.breed
      //                : k=="UserNextAppointmentLength" ? this.usenextappointment.length
      //                 : k=="next_appointment_date" ? this.prescription.nextappointment
      //                  : k=="prescription" ? this.prescription.prescription
      //                   : '');
      // });
      // this.template.Body = JSON.parse(this.template.Body).html;
      // console.log(this.template.Body)
      // })
    },
    getPrescriptions() {
      this.$API.veterinaryprescription
        .getVeterinaryPrescription(this.patientinfo.id_patient)
        .then((response) => {
          this.prescriptions = response
          let day = this.prescription.prescriptiondate;
        
          response.forEach((el) => {
            if (el.PrescriptionDate == day) {
              this.selectedPrescription(el)
            }
          })
        })
    },
    selectedPrescription(data) {
      Object.keys(data).map((el) => {
        Vue.set(this.prescription, el.toLowerCase(), data[el])
        this.prescription.ID_PVP = data.ID_PVP
        
      })
      this.prescription.Weight = data.Weight;
      if(this.prescription.Weight != null && this.prescription.Weight.includes('Kg')) {
        this.weightunit = 'Kg';
        this.weight = this.prescription.Weight.slice(0, -2).replace(' ', '');
      }
      else if(this.prescription.Weight != null && this.prescription.Weight.includes('Lb')) {
        this.weightunit = 'Lb';
        this.weight = this.prescription.Weight.slice(0, -2).replace(' ', '');
      }
      else {
        this.weightunit = '';
        this.weight = this.prescription.Weight != null ? this.prescription.Weight.replace(' ', '') : '';
      }
      if (this.prescription.nextappointment != null) {
        this.usenextappointment.push("Appointment")
      } else {
        this.prescription.nextappointment = this.moment().format("DD/MM/YYYY")
        this.usenextappointment = []
      }
      this.selectedvalues = false
    },
    addPrescription() {
      const formdata = new FormData()
      if (this.usenextappointment.length < 1) {
        this.prescription.nextappointment = null
      }
      Vue.set(this.prescription, "id_user_insert", this.id_user_login)

        if (this.prescription.id_patient === null || this.prescription.id_patient === undefined) {
            Vue.set(this.prescription, "id_patient", this.patientinfo.id_patient)
        }
        if(this.prescription.Weight && this.weightunit) this.prescription.Weight = this.prescription.Weight.concat(' ', this.weightunit);

        formdata.append(
            "veterinaryprescription",
            JSON.stringify(this.prescription)
        )

      this.$API.veterinaryprescription
        .addVeterinaryPrescription(formdata)
        .then(() => {
          this.loading = false
          this.prescription.id_pvp = ''
          this.showAlert("success", "Exito", "la prescripcion fue agregada de manera exitosa")
          this.$store.commit('vetPatientData/setReloaders', 'VeterinaryMedicalH')
          //this.$store.dispatch('reloadData', 'veterinarypatients')
          this.getPrescriptions()
        })
        .catch(() => {
          this.loading = false
          this.showAlert("danger", "Error", "No se pudo agregar la prescripcion")
        })
    },

    updatePrescription() {
      this.loading = true
      const formdata = new FormData()
      if (this.usenextappointment.length == 0) {
        this.prescription.nextappointment = null
      }
      if(this.prescription.Weight) {
        if(!this.prescription.Weight.includes('Lb') && !this.prescription.Weight.includes('Kg')) {
          this.prescription.Weight = this.prescription.Weight.concat(' ', this.weightunit);
        } 
        else if(!this.prescription.Weight.includes(this.weightunit)) {
          const newValue = this.prescription.Weight.slice(0, -3);
          this.prescription.Weight = newValue.concat(' ', this.weightunit);
        }
      } 
      Vue.set(this.prescription, "id_user_update", this.id_user_login)
      formdata.append(
        "veterinaryprescription",
        JSON.stringify(this.prescription)
      )
      this.$API.veterinaryprescription
        .updateVeterinaryPrescription(this.prescription.id_pvp, formdata)
        .then(() => {
          this.loading = false
          // this.$emit('reloadData');
          this.showAlert("success", "Exito", "Se actualizo el registro al paciente")
          this.prescription.id_pvp = ''
          //this.$store.dispatch('reloadData', 'veterinarypatients')
          this.getPrescriptions()
        })
        .catch(() => {
          this.loading = false
          this.showAlert("danger", "Error", "No se pudo actualizar el registro")
        })
    },

    deletePrescription(data) {
      this.loading = true
      const id = data.id_pvp ? data.id_pvp : data.ID_PVP

      this.$API.veterinaryprescription
        .deleteVeterinaryPrescription(id)
        .then(() => {
          this.loading = false
          this.showAlert("success", "Exito", "Se elimino el registro al paciente")
          this.prescription.id_pvp = ''
          this.getPrescriptions()
          this.selectedvalues = true
          //this.$store.dispatch('deleteWindowByUuid', {uuid: this.win.uuid});
          //this.$store.dispatch('reloadData', 'veterinarypatients')
        })
        .catch(() => {
          this.loading = false
          this.showAlert("danger", "Error", "No se pudo eliminar el registro")
        })
    },

    onEditorChange(e) {
      this.prescription.prescription = e.html
    },
    NextAppointmentFunction(e) {
      if (e[0] == "Appointment") this.usenextappointment.push("Appointment")
      else this.usenextappointment = []
    },

    Print() {
      this.$print(this.$refs.print)
    },

    getInfoBranch() {
      this.$API.branches.findSpecificBranch(this.searchbusiness)
        .then(res => {
          this.infobusiness = res[0]
        })
    },

    resetData() {
      this.prescription = {}
      this.prescription.Weight = this.data.Weight;
      this.branchConfig = JSON.parse(localStorage.getItem("branch"));
      if(this.branchConfig?.weightunit) this.weightunit = this.branchConfig.weightunit;
      if(this.data?.weightunit) this.weightunit = this.data.weightunit;
      
      const localparams = JSON.parse(localStorage.getItem("localparams")) || null;
      if (localparams && localparams.weightunit) this.weightunit = localparams.weightunit;
      if(this.prescription && this.prescription.Weight.includes('Kg')) this.weightunit = 'Kg';
      if(this.prescription && this.prescription.Weight.includes('Lb')) this.weightunit = 'Lb';
      if(this.prescription.Weight && this.prescription.Weight.includes(this.weightunit)) {
        this.weight = this.prescription.Weight.slice(0, -2).replace(' ', '');
      } else {
        this.weight = this.prescription.Weight.replace(' ', '');
      }

      this.prescription.prescriptiondate = this.moment().format("DD/MM/YYYY")
      this.prescription.nextappointment = this.moment().format("DD/MM/YYYY")
      this.selectedvalues = true
    },

    closeAlert() {
      this.alert.show = false
      if (this.alert.options == "deleteprescription") {
        this.confirmtoDelete = null
      }
    },
    acceptAlert() {
      this.alert.show = false

      if (this.alert.options == "deleteprescription") {
        this.deletePrescription(this.confirmtoDelete)
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type
      this.alert.type = type
      this.alert.header = header
      this.alert.body = body
      this.alert.show = true
      this.alert.options = options != null ? options : null
    },
    filterHandler(response) {
      console.log("se pone la response ", response)
      this.prescriptions = response
    },
  },

}
</script>

<style>
.ql-editor {
	font-size: 2em;
}
</style>

<style scoped>
.btndisable {
    background-color: #41464c !important;
    color: white;
}
.btndisable2 {
    background-color: #1976d2 !important;
    color: white;
}

.tabs {
    width: 100%;
    padding: 10px;
    background-color: #1976d2;
    color: white;
}

.tabs:hover {

  cursor: pointer;
}

.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;

}

.infopatient {
    margin-top: 5%;
}

.btnblack {
  background-color: rgba(60, 60, 60, 1) !important;
  color: white !important;

}

.btndelete {
    background-color: #E3191E !important;
    color: white;
}


.btn-add {
    background-color: #3498DB !important;
    color: white;
}

.btnsave {
  background-color: #00A178 !important;
  color: white;

}

.btnclean {
  background-color: #F29D35 !important;
  color: white;

  margin-right: .3rem;
}

.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  background-color: white;

  border-radius: 15px;


}

.btnblue {
    background-color: #1976d2 !important;
    color: white;
}
.ql-size-10px {
  font-size: 10px;
}
.ql-size-11px {
  font-size: 11px;
}
.ql-size-12px {
  font-size: 12px;
}
.ql-size-13px {
  font-size: 13px;
}
.ql-size-14px {
  font-size: 14px;
}
.ql-size-15px {
  font-size: 15px;
}
.ql-size-16px {
  font-size: 16px;
}

@media print {
    body {
        margin: 0;
        padding: 0;
    }
    .page {
        width: 216mm;
        height: 129.5mm;
        overflow: hidden;
    }
}
</style>
