import axios from "./axios"
import { userData } from "./Currentuserdata"


export default {
    /**
     * Get All Customers
     * @api {Get} managecustomer
     * @param {object} params
     */
    getProductsToBill(params) {
        const options = {
            method: 'GET',
            url: 'manageproduct/provider',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },


    /**
     * Get All Products
     * @api {Get} manageproduct/provider
     */
    getProducts(params) {
        const options = {
            method: 'GET',
            url: 'manageproduct',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error)
                    reject(error.message)
                })
        })
    },

    getProductsHistory(params) {
        const options = {
            method: 'GET',
            url: 'manageproduct/products/history',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error)
                    reject(error.message)
                })
        })
    },

    getProductByID(params) {
        const options = {
            method: 'GET',
            url: 'manageproduct/products/findProductById',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error)
                    reject(error.message)
                })
        })
    },

    filterProducts(params) {
        const options = {
            method: 'GET',
            url: 'manageproduct/products/filtered',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error)
                    reject(error.message)
                })
        })
    },

    filterProductSpecialInventory(params) {
        const options = {
            method: 'GET',
            url: 'manageproduct/products/filtered/specialinventory',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error)
                    reject(error.message)
                })
        })
    },

    filterProductsColumn(params) {
        const options = {
            method: 'GET',
            url: 'manageproduct/products/filteredcolumn',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error)
                    reject(error.message)
                })
        })
    },


    /**
     * Add Product
     * @api {POST} manageproduct
     */
    addProduct(data) {
        const options = {

            method: 'POST',
            url: 'manageproduct',
            headers: { 'content-type': 'multipart/form-data' },
            data: data
        }

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    /**
    * get Products of provider
    * @api {POST} manageproduct
    */
    getProductsProvider(data) {
        const options = {

            method: 'POST',
            url: 'manageproduct/providerproducts',
            headers: { 'content-type': 'application/json' },
            data: data
        }

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getProductsProviderHistory(data) {
        const options = {

            method: 'POST',
            url: 'manageproduct/providerproducts/list/history',
            headers: { 'content-type': 'application/json' },
            data: data
        }

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getProductsProviderFiltered(data) {
        const options = {

            method: 'POST',
            url: 'manageproduct/providerproducts/list/filtered',
            headers: { 'content-type': 'application/json' },
            data: data
        }

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getProductsWarehouse(data) {
        const options = {

            method: 'POST',
            url: 'manageproduct/warehouseproducts',
            headers: { 'content-type': 'application/json' },
            data: data
        }

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getProductsWarehouseHistory(data) {
        const options = {

            method: 'POST',
            url: 'manageproduct/warehouseproducts/list/history',
            headers: { 'content-type': 'application/json' },
            data: data
        }

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getProductsWarehouseFiltered(data) {
        const options = {

            method: 'POST',
            url: 'manageproduct/warehouseproducts/list/filtered',
            headers: { 'content-type': 'application/json' },
            data: data
        }

        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    /**
     * Update Product
     * @api {PUT} manageproduct
     */
    updateProduct(id, data) {

        const options = {
            method: 'PUT',
            url: `manageproduct/${id}`,
            headers: { 'content-type': 'multipart/form-data' },
            data: data
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error)
                    reject(error.message)
                })
        })
    },

    /**
     * Delete provider
     * @api {DELETE} manageprovider/:id
     */
    deleteProduct(id) {
        const options = {
            method: 'DELETE',
            url: `manageproduct/${id}`,
            headers: { 'content-type': 'application/json' },
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    logicalDeleteProduct(id) {
        const options = {
            method: "PUT",
            url: `manageproduct/logicaldelete/${id}`,
            headers: { "content-type": "application/json" },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.message)
                });
        });
      },

    /**
     * Get products price list
     */
    priceList() {
        const user = userData()
        const options = {
            method: 'GET',
            url: 'manageproduct/pricelistnoparam',
            headers: { 'content-type': 'application/json' },
            data: {
                ID_Branch: user.branch,
                ID_Business: user.businessid,
            }
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    /**
     * Get history product
     * @api {Get} manageinventory
     */
    getHistoryProduct(params) {
        const options = {
            method: 'GET',
            url: 'manageinventory',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getAllBatch(params) {
        const options = {
            method: 'GET',
            url: 'manageinventory/getAllBatch',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    /**
     * Get IN's history product
     * @api {Get} manageinventory/getIns
     */
    getINsHistoryProduct(params) {
        const options = {
            method: 'GET',
            url: 'manageinventory/getIns',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getINsHistoryProductHistory(params) {
        const options = {
            method: 'GET',
            url: 'manageinventory/getIns/list/history',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getINsHistoryProductFiltered(params) {
        const options = {
            method: 'GET',
            url: 'manageinventory/getIns/list/filtered',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    /**
     * Get IN's history product
     * @api {Get} manageinventory/getInventory
     */
    getProductInventory(params) {
        const options = {
            method: 'GET',
            url: 'manageinventory/getInventory',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getProductInventoryHistory(params) {
        const options = {
            method: 'GET',
            url: 'manageinventory/getInventory/list/history',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getProductInventoryHistoryByColumns(params) {
        const options = {
            method: 'GET',
            url: 'manageinventory/getInventory/list/historybycolumns',
            headers: { 'content-type': 'application/json' },
            params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getProductInventoryFiltered(params) {
        const options = {
            method: 'GET',
            url: 'manageinventory/getInventory/list/filtered',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    /** API'S FOR PURCHASE ORDERS **/


    /**
     * Get All Products to Bill For Purchase Orders
     * @api {Get} manageproducts
     * @param {object} params
     */
    getProductsToBillForPurchaseOrders(params) {
        const options = {
            method: 'GET',
            url: 'manageproduct/purchaseorders/products',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getProductsToBillForPurchaseOrdersHistory(params) {
        const options = {
            method: 'GET',
            url: 'manageproduct/purchaseorders/products/list/history',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getProductsToBillForPurchaseOrdersFiltered(params) {
        const options = {
            method: 'GET',
            url: 'manageproduct/purchaseorders/products/list/filtered',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    /**
     * Get All Products to Bill For Invoice
     * @api {Get} manageproducts
     * @param {object} params
     */
    getProductsToBillForInvoice(params) {
        const options = {
            method: 'GET',
            url: 'manageproduct/invoice/products', 
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    /**
     * Get All Products to Bill For Invoice
     * @api {Get} manageproducts
     * @param {object} params
     */
    getProductsToBillForInvoiceFiltered(params) {
        const options = {
            method: 'GET',
            url: 'manageproduct/invoice/products/filtered',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getAllWithServicesFiltered(params) {
        const options = {
            method: 'GET',
            url: 'manageproduct/invoice/productsandservices/filtered',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getKardex(params) {
        const options = {
            method: 'GET',
            url: 'manageinventory/getKardex',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {

                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })

    },

    getKardexHistory(params) {
        const options = {
            method: 'GET',
            url: 'manageinventory/getKardex/list/history',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {

                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })

    },

    getKardexFiltered(params) {
        const options = {
            method: 'GET',
            url: 'manageinventory/getKardex/list/filtered',
            headers: { 'content-type': 'application/json' },
            params: params
        }
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {

                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.message)
                })
        })

    }
}
