<template>
    <window-portal :open="dataProps.show" @close="() => {
        dataProps.show = false
        if(dataProps?.closeCallback){
            dataProps?.closeCallback()
        };
    }
        ">
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center><div><p style="font-size: 30px;">Vista previa de impresión de Factura Electrónica</p></div></center>
            <br/>
            <center>
                <button class="btnclean" style="font-size: 18px;" @click="Print()">
                    Imprimir
                </button>
                &nbsp;&nbsp;
                <button class="btnclean" style="font-size: 18px;" @click="Download()">
                    Descargar
                </button>
                &nbsp;&nbsp;
                <button v-if="!hideQr" class="btnclean" style="font-size: 18px;" @click="sendMail()">
                    Enviar Correo
                </button>
                &nbsp;&nbsp;
                <button v-if="!hideQr" class="btnclean" style="font-size: 18px;" @click="downLoadJSON()">
                    Descargar JSON
                </button>
            </center>
            <center><div v-if="loading"><p>Cargando...</p></div></center>

        </div>
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center v-if="mailLabel">
                {{ mailLabel }}
            </center>
        </div>
        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">
                <div style="margin: 0 auto; width: 1000px;">
                    <div ref="dataprint" style="margin: 0 !important; padding: 0 !important;">
                        <!--div style="position: relative; width: 1000px; min-height: 1294px; background: white;"-->
                        <div v-for="(page, indexPage) in pages" :key="indexPage" style="position: relative; width: 1000px; min-height: 1293px; background: white;">
                            <hr/>
                            <div class="subpage" style="height: 1250px; padding-top: 20px; overflow: hidden;">
                            
                                <div style="margin-left: 80px; margin-right: 50px;">
                                    <div v-if="hideQr" style="width: 100%; background-color: red;">
                                        <h3 style="color: white; text-align: center;">DOCUMENTO SIN VALIDEZ LEGAL</h3>
                                    </div>

                                    <div v-if="dataProps.data.header.FeMhEstado == 'RECHAZADO'" style="width: 100%; background-color: red;">
                                        <h3 style="color: white; text-align: center;">DOCUMENTO RECHAZADO POR HACIENDA</h3>
                                    </div>

                                    <div v-if="dataProps.data.header.FeMhEstado == 'INVALIDADO'" style="width: 100%; background-color: red;">
                                        <h3 style="color: white; text-align: center;">DOCUMENTO INVALIDADO POR HACIENDA</h3>
                                    </div>
                                    
                                    <div style="display: inline-block; width: 100%; vertical-align: top;">
                                        <div style="display: inline-block; margin: 0; padding: 0; width: 40%; vertical-align: top;">
                                            <table style="font-size: 10pt; border-collapse: collapse; width: 100%; display: inline-block; vertical-align: top;">
                                                <tr>
                                                    <td style="padding-right:20px; padding-bottom: 5px; color: white !important;">
                                                        <img style="max-width: 330px !important; height: auto; max-height: 100px !important;" :src="dataProps.data.branch.BranchLogoPath" crossorigin="anonymous" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                </tr>
                                                <tr v-if="dataProps.data.branch.BranchBilledInfo == null || dataProps.data.branch.BranchBilledInfo == ''">
                                                <td style="padding-right:20px; padding-bottom: 5px; font-size: 12pt"><b> {{dataProps.data.user.business}}</b></td>
                                                </tr>
                                                <tr v-if="dataProps.data.branch.BranchBilledInfo == null || dataProps.data.branch.BranchBilledInfo == ''">
                                                <td style="padding-right:20px; padding-bottom: 5px;">{{dataProps.data.branch.BusinessBillingName}}</td>
                                                </tr>
                                                <tr v-if="dataProps.data.branch.BranchBilledInfo == null || dataProps.data.branch.BranchBilledInfo == ''">
                                                <td style="padding-right:20px; padding-bottom: 5px;">{{dataProps.data.branch.DescActividad}}</td>
                                                </tr>
                                                <tr v-if="dataProps.data.branch.BranchBilledInfo == null || dataProps.data.branch.BranchBilledInfo == ''">
                                                <td style="padding-right:20px; padding-bottom: 5px;">{{dataProps.data.branch.BranchLegalAddress}}</td>
                                                </tr>
                                                <tr v-if="dataProps.data.branch.BranchBilledInfo == null || dataProps.data.branch.BranchBilledInfo == ''">
                                                <td style="padding-right:20px; padding-bottom: 5px;">Tel. {{dataProps.data.branch.BranchPhone}}</td>
                                                </tr>
                                                <tr v-if="dataProps.data.branch.BranchBilledInfo == null || dataProps.data.branch.BranchBilledInfo == ''">
                                                <td style="padding-right:20px; padding-bottom: 5px;">Whatsapp. {{dataProps.data.branch.BranchMobile}}</td>
                                                </tr>
                                                <tr v-if="dataProps.data.branch.BranchBilledInfo == null || dataProps.data.branch.BranchBilledInfo == ''">
                                                <td style="padding-right:20px; padding-bottom: 5px;">{{dataProps.data.branch.BranchEmail}}</td>
                                                </tr>

                                                <tr v-if="dataProps.data.branch.BranchBilledInfo != null && dataProps.data.branch.BranchBilledInfo != ''">
                                                <td style="padding-right:20px; padding-bottom: 5px;" v-html="modifiedBranchBilledInfo"></td>
                                                </tr>
                                                
                                                <tr>
                                                <td style="padding-right:20px; padding-bottom: 5px;">NIT: {{BillingNit}}</td>
                                                </tr>
                                                <tr>
                                                <td style="padding-right:20px; padding-bottom: 5px;">NRC: {{BillingNrc}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div style="display: inline-block; margin: 0; padding: 0; width: 60%; vertical-align: top; box-sizing: border-box;">
                                            <table style="font-size: 10pt; border-collapse: collapse; width: 100%; border: 0.5px solid;">
                                                    <tr :style="`background-color: ${ specialinvoicescolor || 'gray' }; color: ${specialInvoicesTitleColor || 'white'}; width: 100% !important;`">
                                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; padding-top: 5px;  color: white !important; text-align: center;" colspan="4">
                                                            <!--{{ !hideQr ? 'DOCUMENTO TRIBUTARIO ELECTRÓNICO' : 'DOCUMENTO SIN VALIDEZ LEGAL' }} <br/> COMPROBANTE DE -->
                                                            {{ dataProps.data.header.InvoiceType == 'FCF' ? "FACTURA CONSUMIDOR FINAL" : ""}}
                                                            {{ dataProps.data.header.InvoiceType == 'CCF' ? "CRÉDITO FISCAL" : ""}}
                                                            {{ dataProps.data.header.InvoiceType == 'FNS' ? "FACTURA SUJETO EXCLUIDO" : ""}}
                                                            {{ dataProps.data.header.InvoiceType == 'FEX' ? "FACTURA DE EXPORTACIÓN" : ""}}
                                                            {{ dataProps.data.header.InvoiceType == 'DNC' ? "NOTA DE CRÉDITO" : ""}}
                                                            {{ dataProps.data.header.InvoiceType == 'DND' ? "NOTA DE DÉBITO" : ""}}
                                                            {{ dataProps.data.header.InvoiceType == 'CDD' ? "DONACIÓN" : ""}}
                                                            {{ dataProps.data.header.InvoiceType == 'DNR' ? "NOTA DE REMISIÓN" : ""}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" style="padding-left: 10px; padding-right: 10px; padding-top: 10px; padding-bottom: 5px;"><b>Código generación:</b></td>
                                                        <td colspan="2" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; padding-top: 10px;">{{dataProps.data.header.CodigoGeneracion}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Sello de recepción:</b></td>
                                                        <td colspan="2" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.SelloRecepcion}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Número de control:</b></td>
                                                        <td colspan="2" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.NumeroControl}}</td>
                                                    </tr>
                                                    <tr>
                                                        <template v-if="dataProps.data.header.InvoiceStatusDescription == 'Cancelada'">
                                                            <td colspan="2" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Fecha emisión:</b></td>
                                                            <td colspan="2" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.InvoiceDate}}</td>    
                                                        </template>
                                                        <template v-else>
                                                            <td colspan="2" style="width: 50% !important; padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Fecha emisión:</b>&nbsp; {{dataProps.data.header.InvoiceDate}}</td>
                                                            <td colspan="2" style="width: 50% !important; padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Fecha vencimiento:</b>&nbsp; {{dateWithDays}}</td>
                                                        </template>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="2" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Correlativo factura:</b></td>
                                                        <td colspan="2" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; ">{{dataProps.data.header.ID_Invoice}}</td>
                                                    </tr>
                                                    <tr v-if="!hideQr">
                                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; text-align: center;" colspan="4">
                                                            <img ref="qrcode" :src="urlQr"/>
                                                        </td>
                                                    </tr>
                                            </table>
                                            <table style="font-size: 10pt; border-collapse: collapse; width: 100%;">
                                                <tr>
                                                    <td style="padding-left: 10px; padding-right: 10px; padding-top: 5px; text-align: left;"><b>Modelo de facturación:</b></td>
                                                    <td style="padding-left: 10px; padding-right: 10px; padding-top: 5px; text-align: left;" v-if="dataProps.data.dteJson.IdentificacionTipoModelo == 1">Previo</td>
                                                    <td style="padding-left: 10px; padding-right: 10px; padding-top: 5px; text-align: left;" v-if="dataProps.data.dteJson.IdentificacionTipoModelo == 2">Diferido</td>
                                                    <td style="padding-left: 10px; padding-right: 10px; padding-top: 5px; text-align: left;"><b>Tipo de transmisión:</b></td>
                                                    <td style="padding-left: 10px; padding-right: 10px; padding-top: 5px; text-align: left;" v-if="dataProps.data.dteJson.IdentificacionTipoOperacion == 1">Normal</td>
                                                    <td style="padding-left: 10px; padding-right: 10px; padding-top: 5px; text-align: left;" v-if="dataProps.data.dteJson.IdentificacionTipoOperacion == 2">Por contingencía</td>
                                                </tr>
                                                <tr>
                                                    
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    
                                    <br/>
                                    <hr/>
                                    <div style="width: 100%;" v-if="dataProps.data.branch.BranchBannerPath != null && dataProps.data.branch.BranchBannerPath != '' && dataProps.data.branch.BranchBannerPath != 'null'">
                                        <table style="font-size: 10pt; border-collapse: collapse; width: 100%;">
                                            <tr>
                                                <td style="padding-left: 0px; padding-right: 0px; padding-bottom: 0px; text-align: center;"><img :src="dataProps.data.branch.BranchBannerPath" width="100%"/></td>
                                            </tr>
                                        </table>
                                    </div>
                                    <br/>
                                    <div style="width: 100%;">
                                        <table style="font-size: 10pt; border-collapse: collapse; width: 100%;" v-if="dataProps.data.header.InvoiceType != 'CCF'">
                                            <tr v-if="dataProps.data.header.ID_Seller">
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Código cliente:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.ID_Customer}}</td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Código vendedor:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.ID_Seller}}</td>
                                            </tr>
                                            <tr>
                                                <td v-if="!dataProps.data.header.ID_Seller" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Código cliente:</b></td>
                                                <td v-if="!dataProps.data.header.ID_Seller" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.ID_Customer}}</td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Razón social:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.InvoiceName}}</td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Dirección:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 300px !important;">{{dataProps.data.header.InvoiceAddress}}</td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>NIT:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.InvoiceNIT}}</td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>DUI:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.InvoiceDUI}}</td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Entidad:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.InvoiceEntity}}</td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Moneda:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.InvoiceCurrency == "$" ? "USD" : ""}}</td>

                                                <td v-if="dataProps.data.header.ZoneClient != '' && dataProps.data.header.ZoneClient != null" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Zona:</b></td>
                                                <td v-if="dataProps.data.header.ZoneClient != '' && dataProps.data.header.ZoneClient != null" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.ZoneClient}}</td>
                                            </tr>
                                            <tr v-if="!lexlitislatamclientdata">
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Estado de la factura:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{ dataProps.data.header.InvoiceStatusDescription }}</td>
                                            </tr>
                                        </table>
                                        <table style="font-size: 10pt; border-collapse: collapse; width: 100%;" v-else-if="kitcoalternativeposclientdata">
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Razón social:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.InvoiceName}}</td>
                                                
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>NIT:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.InvoiceNIT}}</td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Dirección:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 300px !important;">{{dataProps.data.header.InvoiceAddress}}</td>

                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>NRC:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.InvoiceNRC}}</td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Actividad económica:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.dteJson.ReceptorDescActividad}}</td>
                                                
                                                <!--td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Entidad:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.InvoiceEntity}}</td-->
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Forma de pago:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{ dataProps.data.header.InvoiceStatusDescription }}</td>
                                                
                                                <td v-if="dataProps.data.header.ZoneClient != '' && dataProps.data.header.ZoneClient != null" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Zona:</b></td>
                                                <td v-if="dataProps.data.header.ZoneClient != '' && dataProps.data.header.ZoneClient != null" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.ZoneClient}}</td>
                                            </tr>
                                        </table>
                                        <table style="font-size: 10pt; border-collapse: collapse; width: 100%;" v-else>
                                            <tr v-if="dataProps.data.header.ID_Seller">
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Código cliente:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.ID_Customer}}</td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Código vendedor:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.ID_Seller}}</td>
                                            </tr>
                                            <tr>
                                                <td v-if="!dataProps.data.header.ID_Seller" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Código cliente:</b></td>
                                                <td v-if="!dataProps.data.header.ID_Seller" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.ID_Customer}}</td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Razón social:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.InvoiceName}}</td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Dirección:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 300px !important;">{{dataProps.data.header.InvoiceAddress}}</td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>NIT:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.InvoiceNIT}}</td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>NRC:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.InvoiceNRC}}</td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Entidad:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.InvoiceEntity}}</td>
                                            </tr>
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Actividad económica:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.dteJson.ReceptorDescActividad}}</td>
                                                
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Moneda:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.InvoiceCurrency == "$" ? "USD" : ""}}</td>
                                            </tr>
                                            <tr v-if="!lexlitislatamclientdata">
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Estado de la factura:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{ dataProps.data.header.InvoiceStatusDescription }}</td>

                                                <td v-if="dataProps.data.header.ZoneClient != '' && dataProps.data.header.ZoneClient != null" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Zona:</b></td>
                                                <td v-if="dataProps.data.header.ZoneClient != '' && dataProps.data.header.ZoneClient != null" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.ZoneClient}}</td>
                                            </tr>
                                            <tr v-else>
                                                <td v-if="dataProps.data.header.ZoneClient != '' && dataProps.data.header.ZoneClient != null" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;"><b>Zona:</b></td>
                                                <td v-if="dataProps.data.header.ZoneClient != '' && dataProps.data.header.ZoneClient != null" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">{{dataProps.data.header.ZoneClient}}</td>
                                            </tr>
                                        </table>
                                        <table v-if="(keyMaster == '22ddfc1b81d28286daec551efa268b0d' || keyMaster == '1f4e233a5eef560814ecf400f0e4c642') && dataProps.data.header.FullNameSeller" style="font-size: 10pt; border-collapse: collapse; width: 100%;">
                                            <tr>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-top: 5px; text-align: left; width: 100px"><b>Vendedor:</b></td>
                                                <td style="padding-left: 10px; padding-right: 10px; padding-top: 5px; text-align: left;">{{dataProps.data.header.FullNameSeller ? dataProps.data.header.FullNameSeller : ''}}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <template v-if="dataProps.data.header.InvoiceType == 'DNC' || dataProps.data.header.InvoiceType == 'DND'">
                                        
                                        <br/> 
                                        <div style="width: 100%;">
                                            <table style="width: 100% !important; font-size: 11px; border-collapse: collapse; border: 1px solid black;">
                                                <tr style="background: gray;">
                                                    <td colspan="3" style="text-align: center; color: white;">DOCUMENTOS RELACIONADOS</td>
                                                </tr>
                                                <tr>
                                                    <td style="border: 1px solid; padding-left: 2px; width: 35px;">Tipo de Documento</td>
                                                    <td style="border: 1px solid; padding-left: 2px; width: 35px;">Nº de Documento</td>
                                                    <td style="border: 1px solid; padding-left: 2px; width: 35px;">Fecha de Documento</td>
                                                </tr>

                                                <tr v-for="(item, index) in dataProps.data.docRelated" :key="index">
                                                    <td style="border-left: 1px solid; border-right: 1px solid;">
                                                        {{ item.TipoDocumentoRelacionado == "03" ? "Comprobante de Crédito Fiscal" : "" }}
                                                    </td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.NumeroDocumentoRelacionado }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.FechaEmisionRelacionado }}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </template>

                                    <br/>
                                    <div style="width: 100%;">
                                        <table style="width: 100% !important; font-size: 11px; border-collapse: collapse; border: 1px solid black;">
                                            <tr :style="`background: ${ specialinvoicescolor || 'gray' };`">
                                                <td colspan="11" style="text-align: center; color: white;">CUERPO DEL DOCUMENTO</td>
                                            </tr>

                                            <tr>
                                                <td style="border: 1px solid; padding-left: 2px; width: 35px;">No.<br/>Item</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 35px;">Tipo<br/>Item</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 35px;">Cant.</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 95px;">Código</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 95px;">Código<br/>Alterno</td>
                                                <td style="border: 1px solid; padding-left: 2px;">Descripcion</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 65px;">Precio<br/>unitario {{dataProps.data.header.InvoiceCurrency}}</td>
                                                <td style="border: 1px solid; padding-left: 2px; width: 65px;">Descuento {{dataProps.data.header.InvoiceCurrency}}</td>
                                                <td v-if="dataProps.data.header.InvoiceType != 'FEX'" style="border: 1px solid; padding-left: 2px; width: 65px;">Ventas<br/>exentas {{dataProps.data.header.InvoiceCurrency}}</td>
                                                <td v-if="dataProps.data.header.InvoiceType != 'FEX'" style="border: 1px solid; padding-left: 2px; width: 65px;">Ventas no<br/>sujetas {{dataProps.data.header.InvoiceCurrency}}</td>
                                                <td v-if="dataProps.data.header.InvoiceType == 'FEX'" style="border: 1px solid; padding-left: 2px; width: 65px;">Otros montos no afectos {{dataProps.data.header.InvoiceCurrency}}</td>
                                                <td v-if="dataProps.data.header.InvoiceType != 'FEX'" style="border: 1px solid; padding-left: 2px; width: 65px;">Ventas <br/>gravadas {{dataProps.data.header.InvoiceCurrency}}</td>
                                                <td v-if="dataProps.data.header.InvoiceType == 'FEX'" style="border: 1px solid; padding-left: 2px; width: 65px;">Ventas <br/>afectas {{dataProps.data.header.InvoiceCurrency}}</td>
                                            </tr>
                                            <!--tr v-for="(item, index) in dataProps.data.body" :key="index"-->
                                            <tr v-for="(item, index) in valuesRows[indexPage]" :key="index">
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{ typePrint == 0 ? (index+1)+(40*indexPage) : (index+1)+(30*indexPage) }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">3</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.ItemQuantity }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.ID_Item }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.PrincipalBarCode }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid;">{{ item.ItemDescription }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ numberWithCommas(parseFloat(item.ItemUnitPrice).toFixed(2)) }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ numberWithCommas(parseFloat(item.ItemDiscount).toFixed(2)) }}</td>
                                                <td v-if="dataProps.data.header.InvoiceType != 'FEX'" style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ numberWithCommas(parseFloat(item.ItemExenta).toFixed(2)) }}</td>
                                                <td v-if="dataProps.data.header.InvoiceType != 'FEX'" style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ numberWithCommas(parseFloat(item.ItemNoSuj).toFixed(2)) }}</td>
                                                <td v-if="dataProps.data.header.InvoiceType == 'FEX'" style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ numberWithCommas(parseFloat(0).toFixed(2)) }}</td>
                                                <td style="border-left: 1px solid; border-right: 1px solid; text-align: right;">{{ numberWithCommas(parseFloat(item.ItemGravada).toFixed(2)) }}</td>
                                            </tr>

                                            <tr v-if="indexPage == (numPages-1)">
                                                <td colspan="6" style="text-align: center; border: 1px solid;">
                                                    <b>Valor en letras: </b> {{dataProps.data.header.InvoiceTotalLetras}}
                                                </td>

                                                <td colspan="5" style="border: 1px solid;">
                                                    <table style="width: 100% !important; font-size: 11px; text-align: right;">

                                                        <template v-if="dataProps.data.header.InvoiceType == 'CCF'">
                                                            <tr>
                                                                <td>Sub total ventas gravadas</td>
                                                                <td>{{ dataProps.data.header.InvoiceCurrency }}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceSubTotalVentas).toFixed(2))}}</td>
                                                            </tr>   
                                                            <tr>
                                                                <td>Total descuentos</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceTotalDescu).toFixed(2))}}</td>
                                                            </tr>   
                                                            <tr>
                                                                <td>IVA</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceTotalIva).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Sub total</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceMontoTotalOperacion).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>IVA Retenido</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceIvaRete1).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>IVA Percibido</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceIvaPerce1).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Impuesto sobre la renta</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceReteRenta).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="parseFloat(dataProps.data.header.Fovial) >  0">
                                                                <td>Fovial</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.Fovial).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="parseFloat(dataProps.data.header.Cotrans) >  0">
                                                                <td>Cotrans</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.Cotrans).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr style="font-size: 12px;">
                                                                <td><b>Total</b></td>
                                                                <td><b>{{dataProps.data.header.InvoiceCurrency}}</b></td>
                                                                <td style="text-align: right; width: 50px;"><b>{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceTotalPagar).toFixed(2))}}</b></td>
                                                            </tr>                                                          
                                                        </template>

                                                        <template v-if="dataProps.data.header.InvoiceType == 'FCF' || dataProps.data.header.InvoiceType == 'CDD' || dataProps.data.header.InvoiceType == 'DNR'">
                                                            <tr>
                                                                <td>Sub total de ventas</td>
                                                                <td>{{ dataProps.data.header.InvoiceCurrency }}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceSubTotalVentas).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total descuentos</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceTotalDescu).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>IVA Retenido</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceIvaRete1).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Impuesto sobre la renta</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceReteRenta).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="parseFloat(dataProps.data.header.Fovial) >  0">
                                                                <td>Fovial</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.Fovial).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="parseFloat(dataProps.data.header.Cotrans) >  0">
                                                                <td>Cotrans</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.Cotrans).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr style="font-size: 12px;">
                                                                <td><b>Total</b></td>
                                                                <td><b>{{dataProps.data.header.InvoiceCurrency}}</b></td>
                                                                <td style="text-align: right; width: 50px;"><b>{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceTotalPagar).toFixed(2))}}</b></td>
                                                            </tr>
                                                        </template>

                                                        <template v-if="dataProps.data.header.InvoiceType == 'FEX'">
                                                            <tr>
                                                                <td>Total de Operaciones Afectas</td>
                                                                <td>{{ dataProps.data.header.InvoiceCurrency }}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceSubTotalVentas).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Monto global de Desc., Rebajas de operaciones afectas</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceTotalDescu).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="dataProps.data.header.InvoiceType === 'FEX'">
                                                                <td>Flete</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.Flete).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="dataProps.data.header.InvoiceType === 'FEX'">
                                                                <td>Seguro</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.Seguro).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr style="font-size: 12px;">
                                                                <td><b>Monto Total de la Operación</b></td>
                                                                <td><b>{{dataProps.data.header.InvoiceCurrency}}</b></td>
                                                                <td style="text-align: right; width: 50px;"><b>{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceTotalPagar).toFixed(2))}}</b></td>
                                                            </tr>
                                                        </template>

                                                        <template  v-if="dataProps.data.header.InvoiceType == 'FNS'">
                                                            <tr>
                                                                <td>Sub total de ventas</td>
                                                                <td>{{ dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceSubTotalVentas).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Total descuentos</td>
                                                                <td>{{ dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceTotalDescu).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>IVA Retenido</td>
                                                                <td>{{ dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceIvaRete1).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Impuesto sobre la renta</td>
                                                                <td>{{ dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceReteRenta).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="dataProps.data.header.InvoiceType === 'FEX'">
                                                                <td>Flete</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.Flete).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr v-if="dataProps.data.header.InvoiceType === 'FEX'">
                                                                <td>Seguro</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.Seguro).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr style="font-size: 12px;">
                                                                <td><b>Total</b></td>
                                                                <td><b>{{ dataProps.data.header.InvoiceCurrency}}</b></td>
                                                                <td style="text-align: right; width: 50px;"><b>{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceTotalPagar).toFixed(2))}}</b></td>
                                                            </tr>
                                                        </template>

                                                        <template v-if="dataProps.data.header.InvoiceType == 'DNC' || dataProps.data.header.InvoiceType == 'DND'">
                                                            <tr>
                                                                <td>Sub total de ventas</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceSubTotalVentas).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Monto global Des., Rebajas y otros a ventas no sujetas</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceDescuNoSuj).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Monto global Des., Rebajas y otros a ventas exentas</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceDescuExenta).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Monto global Des., Rebajas y otros a ventas gravadas</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceDescuGravada).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Impuesto al Valor Agregado 13%</td>
                                                                <td>{{dataProps.data.header.InvoiceCurrency}}</td>
                                                                <td style="text-align: right; width: 50px;">{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceTotalIva).toFixed(2))}}</td>
                                                            </tr>
                                                            <tr style="font-size: 12px;">
                                                                <td><b>Total</b></td>
                                                                <td><b>{{dataProps.data.header.InvoiceCurrency}}</b></td>
                                                                <td style="text-align: right; width: 50px;"><b>{{numberWithCommas(parseFloat(dataProps.data.header.InvoiceTotalPagar).toFixed(2))}}</b></td>
                                                            </tr>
                                                        </template>
                                                    </table>
                                                </td>
                                            </tr>
                                            <template v-if="dataProps.data.header.InvoiceInternalNote"> 
                                                <tr> 
                                                    <td colspan="10" style="padding: 5px;">
                                                        <b>Observaciones: </b> {{ commentsOuterHtml }}
                                                    </td>
                                                </tr>
                                            </template>
                                        </table>
                                    </div>
                                </div>
                                
                                <div style="position: absolute; bottom: 0; width: 100%; left: 80px">
                                    <p style="font-size:10px;">Pagina {{ indexPage+1 }} de {{ numPages }}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
import QRCode from 'qrcode';
//import html2canvas from "html2canvas"
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"
import { addFormatToNIT, addFormatNRC } from "../../helpers/misc"
import { numberWithCommas } from "../../helpers/money";
import {numberToText} from '@/helpers/conveterNumberText.js'

export default {
    name: "PrintPosFa",
    props: {
        dataProps: {
            type: Object,
        },
        hideQr: {
            type: Boolean,
            default: false
        }
    },
    components: {WindowPortal},
    data() {
        return {
            header: null,
            footer: null,
            loading: false,
            //seal: "",
            //sealBranch: "",
            numPages: 0,
            
            //valuesRows: []
            mailLabel: null,
            valuesRows: [],
            urlQr: null,
            typePrint: 0,
            specialinvoicescolor: JSON.parse( localStorage.getItem( "user" ) ).specialinvoicescolor,
            kitcoalternativeposclientdata: JSON.parse(localStorage.getItem("user")).kitcoalternativeposclientdata,
            lexlitislatamclientdata: JSON.parse(localStorage.getItem("user")).lexlitislatamclientdata,
            keyMaster: JSON.parse(localStorage.getItem("user")).keymaster,
        }
    },
    mounted() {
        //console.log(this.dataProps);
        //console.log(this.dataProps.data.branch)
        //console.log(this.dataProps.data.dteJson)
        //this.seal = JSON.parse(localStorage.getItem("user")).seal || ""
        //this.sealBranch = JSON.parse(localStorage.getItem("user")).sealBranch || ""
        this.getHeaderFooter()
        // if(this.dataProps.)
        // this.$nextTick(() => {
        //     this.sendMail();
        // })
       
    },
    computed: {
        /*edad() {
            let edad = this.dataProps.data.profile.Age
            if (edad == null || edad == undefined) {
                return ''
            } else {
                return edad
            }
        },*/
        BillingNrc() {
            return addFormatNRC(this.dataProps.data.branch.BusinessBillingNRC)
        },
        BillingNit() {
            //console.log(this.dataProps.data.branch.BusinessBillingNIT);
            return addFormatToNIT(this.dataProps.data.branch.BusinessBillingNIT)
        },
        pages() {
            // Generar un arreglo con `numPages` elementos
            return Array.from({ length: this.numPages });
        },
        commentsOuterHtml() {
            return this.dataProps.data.header.InvoiceInternalNote.replace(/<[^>]*>/g, '')
        },
        modifiedBranchBilledInfo() {
            const info = this.dataProps.data.branch.BranchBilledInfo
            if (info && info !== '') {
                return info
                    .replace(/<p>/g, '')
                    .replace(/<\/p>/g, '<br/>')
            }

            return null;
        },
        dateWithDays() {
            let fecha = this.dataProps.data.header.InvoiceDate;
            let dias = this.dataProps.data.header.InvoiceCreditDay;

            const partesFecha = fecha.split('/');
            const dia = parseInt(partesFecha[0], 10);
            const mes = parseInt(partesFecha[1], 10) - 1; 
            const anio = parseInt(partesFecha[2], 10);
            const fechaInicial = new Date(anio, mes, dia);

            const diasAgregar = parseInt(dias.split(' ')[0], 10);

            fechaInicial.setDate(fechaInicial.getDate() + diasAgregar);

            const diaNuevo = ('0' + fechaInicial.getDate()).slice(-2);
            const mesNuevo = ('0' + (fechaInicial.getMonth() + 1)).slice(-2);
            const anioNuevo = fechaInicial.getFullYear();

            return `${diaNuevo}/${mesNuevo}/${anioNuevo}`;
        },
    },
    watch: {
        'dataProps.show'() {
            if(this.dataProps.show){
                setTimeout(async () => {
                    await this.calculatePagesOfDoc()
                    //console.log('header ', this.dataProps.data.header);
                    const partes = this.dataProps.data.header.InvoiceDate.split("/");
                    const dia = partes[0];
                    const mes = partes[1];
                    const anio = partes[2];

                    // Crear una nueva fecha en formato "yyyy-mm-dd"
                    const fechaNueva = `${anio}-${mes}-${dia}`;
                    const url = this.dataProps.data.branch.AuthFeMode == 1 ? `https://admin.factura.gob.sv/consultaPublica?ambiente=01&codGen=${this.dataProps.data.header.CodigoGeneracion}&fechaEmi=${fechaNueva}` : `https://admin.factura.gob.sv/consultaPublica?ambiente=00&codGen=${this.dataProps.data.header.CodigoGeneracion}&fechaEmi=${fechaNueva}`;
                    
                    // Generar el código QR y mostrarlo en el elemento con id "qrcode"

                    const opt = {
                        errorCorrectionLevel: 'H',
                        type: 'image/png',
                        quality: 0.3,
                        margin: 1,
                        color: {
                            dark:"#000000",
                            light:"#ffffff"
                        },
                        width: 100
                    }

                    // QRCode.toCanvas(this.$refs.qrcode, url, { width: 100 }, (error) => {
                    //     if (error) {
                    //         console.log('Error al generar el código QR:', error);
                    //     }
                    // });
                    
                    QRCode.toDataURL(url, opt, (error, url) => {
                        if (error) {
                            console.log('Error al generar el código QR:', error);
                            throw error;
                        }

                        //console.log(url);
                        this.urlQr = url
                        //this.$refs.qrcode.src = url;
                    });
                    await this.getImages()
                }, 0);

                // this.$nextTick(() => {
                //     this.sendMail();
                // });
                // if(this.dataProps.fromCreateInvoice) {
                // }
            }
        }
    },
    methods: {
        GetnumberToText(number) {
            if(number) return numberToText(number)
            else return ''
        },
        numberWithCommas(x) {
            return numberWithCommas(x);
        },
        getHeaderFooter() {
            let search = {};
            search.ID_business = JSON.parse(localStorage.getItem("user")).businessid || ""
            search.ID_branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.$API.HF.getHF(search).then((data) => {
                let header = [], footer = [];
                header = data.filter((x) => x.State == '1' && x.Type == 'Header');
                this.header = header.length > 0 ? JSON.parse(header[0].Body).html : '';
                this.header = this.header.replace(/(\n)/gm, "");
                footer = data.filter((x) => x.State == '1' && x.Type == 'Footer');
                this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : '';
            })
        },

        calculatePagesOfDoc() {
            let pages = 0
            let rows = 0
            this.valuesRows[pages] = []

            try{
                if(this.dataProps.data.branch.BusinessBillingNRC == '3213715' || this.dataProps.data.branch.BusinessBillingNRC == '2201050') {
                    this.typePrint = 1
                    //One page
                    if(this.dataProps.data.body.length <= 24){
                        for(let i = 0; i < this.dataProps.data.body.length; i++){
                            rows++
                            this.valuesRows[pages].push(this.dataProps.data.body[i])
                        }    
                    }
                    else{
                        for(let i = 0; i < this.dataProps.data.body.length; i++){
                            if(rows < 29 && (i !== this.dataProps.data.body.length - 1)){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                            }
                            else if(rows <= 24){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                            }
                            else{
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                                pages++
                                rows = 0
                                this.valuesRows[pages] = []
                            }
                        }
                    }
                }
                else{
                    this.typePrint = 0
                    //Cuando es solo una página
                    if(this.dataProps.data.body.length <= 34){
                        for(let i = 0; i < this.dataProps.data.body.length; i++){
                            rows++
                            this.valuesRows[pages].push(this.dataProps.data.body[i])
                        }    
                    }
                    else{
                        for(let i = 0; i < this.dataProps.data.body.length; i++){
                            if(rows < 39 && (i !== this.dataProps.data.body.length - 1)){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                            }
                            else if(rows <= 34){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                            }
                            else{
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                                pages++
                                rows = 0
                                this.valuesRows[pages] = []
                            }
                        }
                    }
                }
                
            }
            catch(err) {
                alert(err)
            }
            pages = pages + 1
            this.numPages = pages
        },

        async Print(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            })
            
            
            //doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        },

        async Download(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            });
            
            //doc.deletePage(2)
        doc.save(this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".pdf"); //Agregado por Juan 13082023

            this.loading = false
        },
        async sendMail() {
            try {
                this.mailLabel = null;
                this.mailLabel = 'Enviando...';
                var doc = new jsPDF('p', 'pt', 'letter')
                //console.log(doc);
                //console.log(this.$refs.dataprint);
                await doc.html(this.$refs.dataprint, {
                    callback: function(doc){
                        doc.output()
                    },
                    x: 0,
                    y: 0,
                    margin: [0, 0, 0, 0],
                    html2canvas:{ 
                        scale: 0.612,
                        //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                        useCORS: true,
                        logging: false,
                        allowTaint: true
                    },
                });
                
                this.assignCuerpoDocumento();

                const { data: { header: { jsonMH }, dteJson: { ID_Invoice } } } = this.dataProps;

                const body = {
                    dtefilebuffer: new Buffer.from(doc.output('arraybuffer')),
                    dtejson: { ...jsonMH, ID_Invoice },
                    dtefilebufferfilename: this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".pdf"
                };

                // console.log(body);
                await this.$API.invoices.sendInvoiceMail(body);

                this.mailLabel = 'Se envió el correo correctamente';
                this.$emit('mailSent');
            } catch (error) {
                this.mailLabel = `Ocurrió el siguiente error al enviar el correo ${ error }`;
                // console.log(error);
            }
        },
        assignCuerpoDocumento() {
            this.dataProps.data.header.jsonMH.cuerpoDocumento = this.dataProps.data.body.map( ( item, index ) => ( {
                "descripcion": item.ItemDescription,
                "montoDescu": item.ItemDiscount,
                "codigo": item.ID_Item,
                "ventaGravada": item.ItemGravada,
                "ivaItem": item.ItemIva,
                "ventaNoSuj": item.ItemNoSuj,
                "ventaExenta": item.ItemExenta,
                // "tributos": null,
                "numItem": index + 1,
                "noGravado": item.ItemNoGravada,
                "psv": item.ItemPsv,
                // "tipoItem": 3,
                // "codTributo": null,
                "uniMedida": item.ItemMeasureUnit,
                // "numeroDocumento": null,
                "cantidad": item.ItemQuantity,
                "precioUni": item.ItemUnitPrice
            } ) )
            // for( const item in this.dataProps.data.body ) {
            //      [
            //         "descr"
            //     ]
            // }
        },
        async downLoadJSON() {
            try {
                const aElement = document.createElement('a');
                aElement.setAttribute('download', this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".pdf");
                this.assignCuerpoDocumento();
                const blob = new Blob([JSON.stringify(this.dataProps.data.header.jsonMH)], { type: 'application/json' });
                const href = URL.createObjectURL(blob);
                aElement.href = href;
                aElement.download = this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".json";
                aElement.click();
                URL.revokeObjectURL(href);
            } catch (error) {
                this.mailLabel = 'Ocurrió un error al descargar JSON';
                console.log(error);
            }
        },
        async getImages() {
            const img = this.dataProps.data.branch.BranchBannerPath
            const logo = this.dataProps.data.branch.BranchLogoPath
            const { imageBase64: bannerImageBase64 } = await this.$API.utilities.getImageInBase64({ "file": img })
            this.dataProps.data.branch.BranchBannerPath = `${bannerImageBase64}` 

            const { imageBase64: logoImageBase64 } = await this.$API.utilities.getImageInBase64({ "file": logo })
            this.dataProps.data.branch.BranchLogoPath = `${logoImageBase64}` 
        },
    }
}
</script>

<style scoped>
    .u-row .u-col-100{
        width: 100% !important;
    }
    .btnclean{
        border-radius: 15px;
        border: 1px solid white;
  background-color: #F29D35 !important;
  color: white !important;
}
    .btnclose{
        border-radius: 15px;
        border: 1px solid white;
        background-color: rgb(255, 38, 38) !important;
        color: white !important;
       
    }

    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }

 

</style>
